import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {HomeSlider} from "components/home-slider/HomeSlider";
import {BundleEntry} from "components/bundle-entry/BundleEntry";

const target = document.getElementById('home-slider');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if (target) {
    ReactDOM.render(
        <BundleEntry
            bundleName="home-slider"
            locale={localeCode}>
            <HomeSlider />
        </BundleEntry>,
        document.getElementById('home-slider')
    );
}
