import {post} from "./api";

export const HOME_SLIDER_ENDPOINT = '/api/v1/home/slider';

export async function getHomeSlider() : Promise<HomeSliderItem[]> {
    return post({
        url: HOME_SLIDER_ENDPOINT
    })
}

export interface HomeSliderItem {
    url: string,
    title: string,
    subTitle: string,
    images: {[key: string]: string}
}