import {ReactElement} from "react";
import {HomeSliderItem} from "../../lib/api/homeSliderApi";

interface SliderProps {
    item: HomeSliderItem
}

export function MobileSlider(props: SliderProps) : ReactElement {

    return(
        <div>
            <div className={`${(props.item.title || props.item.subTitle) ? 'gradient-overlay' : ''} embla-home-slider__slide__inner`}>
                <img
                    style={{height: '245px', objectFit: 'cover'}}
                    className={`embla__slide__img tw-max-h-productDetailImageHomeSliderMobile tw-w-sliderImage ${props.item.images["small"] ? 'tw-object-left' : 'tw-object-center'}`}
                    src={props.item.images["small"] ? props.item.images["small"] : props.item.images["big"]}
                    alt={props.item.title}/>
            </div>
            <div className={"tw-absolute tw-bottom-12 tw-left-12 tw-right-12"}>
                <h3 className={"tw-text-sliderTitleMobile tw-text-white tw-font-gotham-black tw-font-bold"}>
                    {props.item.title}
                </h3>
                <p className={"tw-mt-4 tw-text-white tw-text-sliderSubTitleMobile tw-leading-sliderSubTitleMobile"}>
                    {props.item.subTitle}
                </p>
            </div>
        </div>
    )

}