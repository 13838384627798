import {ReactElement} from "react";

export function PrevButton(props: {scrollPrev: () => void}) : ReactElement {
    return(
        <div style={{top: '50%', transform: 'translateY(-50%'}}
             className={"tw-absolute tw-left-0 tw-bottom-80 tw-cursor-pointer tw-hidden md:tw-block"}
             onClick={props.scrollPrev}>
            <svg width="36" height="60" viewBox="0 0 36 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.07081e-08 59.5344C1.75289 59.8403 3.55743 60 5.4 60C22.2999 60 36 46.5685 36 30C36 13.4315 22.2999 -1.1977e-06 5.4 -2.67514e-06C3.55743 -2.83622e-06 1.7529 0.159657 5.20466e-06 0.465642L4.07081e-08 59.5344Z"/>
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.07081e-08 59.5344C1.75289 59.8403 3.55743 60 5.4 60C22.2999 60 36 46.5685 36 30C36 13.4315 22.2999 -1.1977e-06 5.4 -2.67514e-06C3.55743 -2.83622e-06 1.7529 0.159657 5.20466e-06 0.465642L4.07081e-08 59.5344Z" fill="white" fillOpacity="0.9"/>
                <path d="M15 37L8 30L15 23" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </div>
    );
}