import {ReactElement, useCallback, useEffect, useState} from "react";
import {getHomeSlider, HomeSliderItem} from "../../lib/api/homeSliderApi";
import {useEmblaCarousel} from "embla-carousel/react";
import {Indicator} from "./Indicator";
import {PrevButton} from "./PrevButton";
import {NextButton} from "./NextButton";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {MobileSlider} from "./MobileSlider";
import {DesktopSlider} from "./DesktopSlider";

export function HomeSlider() : ReactElement {

    const [isLoading, setIsLoading] = useState(true);
    const [homeSlider, setHomeSlider] = useState<HomeSliderItem[]>();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [mainViewportRef, embla] = useEmblaCarousel();

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback( () => embla && embla.scrollNext(), [embla]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla]);

    const isMobile = !useMediaQuery(mediaQueries.sm);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        embla.on("select", onSelect);
    }, [embla, onSelect]);

    useEffect(() => {
        getHomeSlider()
            .then(result => {
                setHomeSlider(result);
                setIsLoading(false);
            });
    }, [])

    if(isLoading || !homeSlider) {
        return(
            <div className={"tw-bg-skeletonBackgroundGrey embla-home-slider__container tw-flex tw-mb-16"} />
        );
    }

    return(
        <div className={"embla-home-slider md:tw-flex md:tw-flex-1 tw-flex-col tw-mb-16"}>
            <div className={"embla__viewport"} ref={mainViewportRef}>
                <div className={"embla-home-slider__container"}>
                    {homeSlider.map( (item, index) => (
                        <a
                            className={"embla__slide ga-slide-" + index}
                            key={index}
                            href={`${item.url}`}
                            target={`${item.url === 'https://adventskalender.justdrink.ch/' ? "_blank": "_self"}`}>
                            {isMobile ? <MobileSlider item={item} /> : <DesktopSlider item={item} />}
                        </a>
                    ))}
                </div>
            </div>

            {homeSlider.length > 1 &&
                <Indicator
                    sliderLength={homeSlider.length}
                    selectedIndex={selectedIndex}
                    scrollTo={scrollTo} />
            }

            {selectedIndex > 0 &&
                <PrevButton scrollPrev={scrollPrev} />
            }

            {selectedIndex !== homeSlider.length - 1 &&
                <NextButton scrollNext={scrollNext} />
            }
        </div>
    );

}