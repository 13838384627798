import {ReactElement} from "react";

export function NextButton(props: {scrollNext: () => void}) : ReactElement {
    return(
        <div style={{top: '50%', transform: 'translateY(-50%'}}
             className={"tw-absolute tw-right-0 tw-m-auto tw-cursor-pointer tw-hidden md:tw-block ga-next"}
             onClick={props.scrollNext}>
            <svg width="36" height="60" viewBox="0 0 36 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M36 0.465646C34.2471 0.159663 32.4426 0 30.6 0C13.7001 0 0 13.4315 0 30C0 46.5685 13.7001 60 30.6 60C32.4426 60 34.2471 59.8403 36 59.5344V0.465646Z"/>
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M36 0.465646C34.2471 0.159663 32.4426 0 30.6 0C13.7001 0 0 13.4315 0 30C0 46.5685 13.7001 60 30.6 60C32.4426 60 34.2471 59.8403 36 59.5344V0.465646Z" fill="white" fillOpacity="0.9"/>
                <path d="M21 23L28 30L21 37" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
            </svg>

        </div>
    );
}